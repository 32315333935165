import React from 'react';
import Slider from 'react-slick';
import WayFadeUp from '../components/transitions/WayFadeUp';
import '../css/slick.css';
import '../css/service-slider.css';

class ServiceSlider extends React.Component {
  constructor({ props }) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }
  render() {
    const { items } = this.props.input;
    const { title } = this.props.input.primary;
    const settings = {
      dots: false,
      arrows: false,
      fade: true,
      adaptiveHeight: true,
      asNavFor: this.state.nav2,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const settingsNav = {
      dots: false,
      arrows: true,
      fade: true,
      asNavFor: this.state.nav1,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <WayFadeUp>
        <div className="w-100 pl4 pr4 pl5-l pr5-l mw9 center mt4 mb4 mt5-l mb5-l">
          <div className="flex-l bt pt4 pb4 items-center relative justify-between">
            <h4 className="f3 mt0 mb0">{title.text}</h4>
            <div className="w-90-l ml3-l mt3 mt0-l">
              <Slider
                {...settingsNav}
                ref={slider => (this.slider2 = slider)}
                {...settingsNav}
                className="service-slider-nav"
              >
                {items.map((item, index) => (
                  <div className="f3 lh-copy">
                    {index + 1} / {items.length}
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <Slider {...settings} ref={slider => (this.slider1 = slider)} {...settings}>
            {items.map(item => (
              <div>
                <div className="tiempos f3 f2-l lh-copy">{item.service_title.text}</div>
                <div
                  className="lh-copy f4 measure-wide"
                  dangerouslySetInnerHTML={{ __html: item.text.html }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </WayFadeUp>
    );
  }
}
export default ServiceSlider;
