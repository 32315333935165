import React from 'react';
import WayFadeUp from '../components/transitions/WayFadeUp';

const TwoColText = ({ input }) => {
  const { text } = input.primary;
  return (
    <WayFadeUp>
      <div className="relative w-100 pl4 pr4 pl5-l pr5-l mt4 mb4 mt6-l mb6-l mw8 center">
        <div
          className="center two-col lh-copy f4"
          dangerouslySetInnerHTML={{ __html: text.html }}
        />
      </div>
    </WayFadeUp>
  );
};
export default TwoColText;
